import React from 'react';
import { graphql } from 'gatsby';
import { renderToReact } from '../rich-text';

import Layout from '../components/layout';
import SEO from '../components/seo';

import styles from './rich-text-page.module.css';

const RichTextPage = ({ data }) => {
  return (
    <Layout>
      <SEO title={data.page.title} />
      <div className={styles.container}>{renderToReact(data.page.body)}</div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    page: contentfulRichTextPage(id: { eq: $id }) {
      title
      body {
        json
        embeds {
          internal {
            type
          }
          ... on ContentfulAsset {
            fields {
              normalizedContentfulId
            }
            file {
              url
              contentType
            }
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyContentfulFluid_tracedSVG
              aspectRatio
            }
          }
          ... on ContentfulLayout {
            fields {
              normalizedContentfulId
            }
            body {
              json
            }
            styles {
              fields {
                array {
                  key
                  value
                }
              }
            }
          }
          ... on ContentfulGalleryWithCaptionsWidget {
            fields {
              normalizedContentfulId
            }
            entries {
              id
              asset {
                file {
                  url
                  contentType
                }
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyContentfulFluid_tracedSVG
                  aspectRatio
                }
              }
              caption {
                json
              }
            }
          }
          ... on ContentfulGalleryWidget {
            fields {
              normalizedContentfulId
            }
            entries {
              id
              file {
                url
                contentType
              }
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyContentfulFluid_tracedSVG
                aspectRatio
              }
            }
          }
          ... on ContentfulSideBySideWidget {
            fields {
              normalizedContentfulId
            }
            entries {
              id
              file {
                url
                contentType
              }
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyContentfulFluid_tracedSVG
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`;

export default RichTextPage;
